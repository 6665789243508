<template>
    <div>
        <div v-if="show_parts_ready_button == 'manual'">
            <div class="my-2" v-if="selected_actuals.length == 0"><br /></div>
            <div v-else>
                <ButtonSetAsReady :actuals="selected_actuals" />
            </div>
        </div>
        <v-treeview color="accent" open-all class="mb-3" item-disabled="locked" density="compact" selectable select-strategy="auto"
            item-value="id" v-model:selected="selected_actuals" :items="actuals_tree">
            <template v-slot:label="{ item }">
                <span class="text-grey" v-if="item.ready || item.kitted_parts">
                    {{ item.title }}
                </span>
                <span v-else> {{ item.title }} </span>
            </template>
            <template v-slot:append="{ item }">
                <v-chip variant="outlined" size="x-small" v-if="item.ready" color="green">
                    ready
                </v-chip>
                <v-chip variant="outlined" size="x-small" v-else-if="item.kitted_parts" color="info">
                    kitted
                </v-chip>
            </template>
        </v-treeview>
    </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import _ from "lodash"
import ButtonSetAsReady from "../../components/left_menu/ButtonSetAsReady.vue";
import { nameSort } from "/src/utils/basics.js"
import { useConstructiblesStore } from "@/stores/constructibles";
import { useSelectedLogisticsStore } from "@/stores/selected_logistics";
import { useSelectedStore } from "@/stores/selected";
import { useSessionStore } from "@/stores/session";

// Stores
const constructibles_store = useConstructiblesStore();
const selected_logistics_store = useSelectedLogisticsStore();
const selected_store = useSelectedStore();
const session_store = useSessionStore();

// Reactive variables
const selected_actuals = ref([]);
const by_priority = ref(true);

// Computed properties
const project = computed(() => selected_store.selected_project || '');
const batches = computed(() => constructibles_store.batches);
const actuals = computed(() => constructibles_store.allActuals);
const show_parts_ready_button = computed(() => session_store.kitted_parts_behavior === 'manual');

const actuals_tree = computed(() => {
    let actuals_tree = []

    // ITERATE PHASES
    if (project.value && project.value.phases) {
        project.value.phases.forEach((phase) => {

            // ITERATE PHASE BATCHES
            let children_batches = []
            batches.value.filter((b) => b.phase == phase).forEach((b) => {

                // GENERATE BATCH CHILDREN
                let children_actuals = []
                actuals.value
                    .filter((actual) => actual.batch == b.uuid)
                    .forEach((actual) => {
                        children_actuals.push({
                            kitted_parts: actual.status == "kitted_parts",
                            ready: actual.status == "ready",
                            id: actual.uuid,
                            design_name: getDesignName(actual.design),
                            priority: actual.priority,
                            title: `${getDesignName(actual.design)} [${actual.uuid.substring(0, 8)}]`,
                        })
                    })

                // NOW SORT CHILDREN
                let sorted_children
                if (by_priority) {
                    sorted_children = _.orderBy(
                        children_actuals,
                        "priority"
                    )
                } else {
                    sorted_children = children_actuals.sort((a, b) =>
                        nameSort(a.design_name, b.design_name)
                    )
                }

                // ADD BATCH TO PHASE CHILDREN
                children_batches.push({
                    id: b.uuid,
                    title: b.name,
                    locked: sorted_children.length == 0,
                    children: sorted_children,
                })
            })

            // ADD PHASE TO TREE
            actuals_tree.push({
                id: phase,
                title: phase,
                locked: children_batches.length == 0,
                children: children_batches,
            })
        })
    }

    return actuals_tree
});

watch(selected_actuals, (sa) => {
    selected_logistics_store.setSelectedActuals(sa);
});

// Methods
function getDesignName(duuid) {
    return constructibles_store.designNameByUuid(duuid);
}

</script>