<template>
    <div class="mx-4">
        <div v-if="show_parts_ready_button == 'manual'">
            <div class="my-2" v-if="selected_actuals.length == 0"><br /></div>
            <div v-else>
                <ButtonSetAsReady :actuals="selected_actuals" />
            </div>
        </div>
        <v-row>
            <SelectorProductionDate class="mx-auto mb-2" :wide="true" />
        </v-row>
        <v-treeview color="accent" open-all class="ma-3" item-disabled="locked" density="compact" selectable
            select-strategy="auto" item-value="id" v-model:selected="selected_actuals" :items="actuals_tree">
            <template v-slot:label="{ item }">
                <span class="text-grey" v-if="item.ready || item.kitted_parts">
                    {{ item.title }}
                </span>
                <span v-else> {{ item.title }} </span>
            </template>
            <template v-slot:append="{ item }">
                <v-chip variant="outlined" size="x-small" v-if="item.ready" color="green">
                    ready
                </v-chip>
                <v-chip variant="outlined" size="x-small" v-else-if="item.kitted_parts" color="info">
                    kitted
                </v-chip>
            </template>
        </v-treeview>
    </div>
</template>

<script setup>
import _ from "lodash"
import { nameSort } from "/src/utils/basics.js"
import ButtonSetAsReady from "../../components/left_menu/ButtonSetAsReady.vue";
import SelectorProductionDate from "./SelectorProductionDate.vue";

import { usePitStoreBasics } from "@/stores/app_common";
import { useSessionStore } from "@/stores/session";
import { useSelectedLogisticsStore } from "@/stores/selected_logistics";
import { useConstructiblesStore } from "@/stores/constructibles";
import { useSelectedStore } from "@/stores/selected";
import { ref, watch, computed } from "vue";

// Data
const selected_actuals = ref([]);
const loading = ref(false);
const by_priority = ref(true);

// Stores
const pitStoreBasics = usePitStoreBasics();
const constructibles_store = useConstructiblesStore();
const selected_logistics_store = useSelectedLogisticsStore();
const sessionStore = useSessionStore();
const selectedStore = useSelectedStore();

// Computed
const show_parts_ready_button = computed(() => sessionStore.kitted_parts_behavior);
const date = computed(() => selectedStore.selected_date);
const actuals = computed(() => constructibles_store.annotatedActualsFromLevel("all"));

const actuals_tree = computed(() => {
    let actuals_tree = [];

    if (actuals.value.length > 0) {
        let children_actuals = actuals.value.map(actual => {
            let design_name = constructibles_store.designNameByUuid(actual.design, false);
            return {
                kitted_parts: actual.status == "kitted_parts",
                ready: actual.status === "ready",
                id: actual.uuid,
                design_name: design_name,
                priority: actual.priority,
                title: `${design_name} [${actual.uuid.substring(0, 8)}]`,
            };
        });

        let sorted_children;
        if (by_priority.value) {
            sorted_children = _.orderBy(children_actuals, "priority");
        } else {
            sorted_children = children_actuals.sort((a, b) => nameSort(a.design_name, b.design_name));
        }

        console.log("date.value: ", date.value);

        actuals_tree.push({
            id: date.value,
            title: date.value,
            locked: sorted_children.length === 0,
            children: sorted_children,
        });
    }

    if (loading.value) {
        return [{ id: 0, title: "Loading...", locked: true }];
    } else if (actuals_tree.length === 0) {
        return [{ id: 0, title: "No constructibles.", locked: true }];
    } else {
        return actuals_tree;
    }
});

watch(selected_actuals, (sa) => {
    selected_logistics_store.setSelectedActuals(sa);
});

</script>
