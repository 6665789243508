
import bus from '@/utils/event_bus'
import PRODUUZIT_FRONT_ENV from "@/utils/defaults";
import { state_init } from "./state_init";
import { useAppStore } from "@/stores/app"
import { useSelectedStore } from "@/stores/selected"
import { useSessionStore } from "@/stores/session"
import { useCompanyStore } from "@/stores/company"
import { useConstructiblesStore } from "@/stores/constructibles"
import { useViewerStore } from "@/stores/viewer"
import { useRecordsStore } from '@/stores/records';


const viewerMode = function (to, from, next) {
    const app_store = useAppStore()
    app_store.setViewerMode()
    next()
}

const logisticsMode = function (to, from, next) {
    const app_store = useAppStore()
    app_store.setLogisticsMode()
    next()
}

const managerMode = function (to, from, next) {
    const app_store = useAppStore()
    app_store.setManagerMode()
    next()
}


const onlyManagersGuard = function (to, from, next) {
    const session_store = useSessionStore()
    let auth = session_store.user_object.auth
    if (auth.company_admin || auth.company_manager || auth.is_superuser) {
        next()
    } else {
        bus.emit('notification', { timeout: 3000, text: "You don't have permissions to enter this view. Contact your company admin", color: "error" })
        next(false)
    }
}

const onlyAdminsGuard = function (to, from, next) {
    const session_store = useSessionStore()
    let auth = session_store.user_object.auth
    if (auth.company_admin || auth.is_superuser) {
        next()
    } else {
        bus.emit('notification', { timeout: 3000, text: "You don't have permissions to enter this view. Contact your company admin", color: "error" })
        next(false)
    }
}


const hasSessionObjectGuard = function (to, from, next) {
    const session_store = useSessionStore()
    const company_store = useCompanyStore()
    if (session_store.user_object._flag == "not-initialized") {
        session_store.getUserObject()
            .then(() => {
                company_store.loadProjects()
                company_store.loadStandardLibraries()
                next()
            })
    } else {
        next()
    }
}

const check_login_subroutine = function (to, next) {
    const session_store = useSessionStore()
    const is_android = PRODUUZIT_FRONT_ENV.IS_ANDROID
    // Android lock
    if (is_android && !session_store.androidLicensed) {
        bus.emit('notification', { timeout: 3000, text: "Don't have Android license enabled for this user. Contact your company admin.", color: "error" })
        next({ name: 'login', query: { redirect: to.path } })
    }
    // Auth lock
    else if (session_store.authenticated) {
        next()
    } else {
        console.log("here add prop redirect to the next route")
        next({ name: 'login', query: { redirect: to.path } })
    }
}

const isAuthenticated = function (to, from, next) {
    const session_store = useSessionStore()
    if (session_store.user_object._flag == "not-initialized") {
        // Get user
        console.log("[i] Initializing user object.")
        session_store.getUserObject()
            .then(() => {
                check_login_subroutine(to, next)
            })
    } else {
        check_login_subroutine(to, next)
    }


}



const hasModuleForecast = function (to, from, next) {
    const session_store = useSessionStore()
    let company = session_store.user_object.auth.company
    if (company.m_prod_management) {
        next()
    } else {
        bus.emit('notification', { timeout: 3000, text: "Can't access Forecast Module. Contact your company admin", color: "warning" })
        next(false)
    }
}


const hasModuleProduction = function (to, from, next) {
    const session_store = useSessionStore()
    let company = session_store.user_object.auth.company
    if (company && company.m_core) {
        next()
    } else {
        bus.emit('notification', { timeout: 3000, text: "Can't access Production Module. Contact your company admin", color: "warning" })
        next(false)
    }
}

const prevConstHasRCPRunning = function (to, from) {
    if (from.meta?.dont_leave_if_rcp_running && !to.query?.bypass_rcp_check) {
        const records_store = useRecordsStore()
        // const session_store = useSessionStore()

        if (records_store.rcpIsRunning && !records_store.rcpIsReady) {  // && session_store.force_checklist_start  ????????????
            console.log("[v] RCP is running. Can't leave this view.")
            console.log(records_store.rcp)
            // this to be catched by component and eventualy redirected
            bus.emit("events/viewer/launch_rcp_running_dialog", to)
            return false
        }
    }
    return true
}


const PROD_USER_VIEWS = [
    "home",
    "login",
    "qr",
    "account",
    "project_viewer",
    "viewer",
    "assd_viewer",
    "ass_viewer",
    "modd_viewer",
    "mod_viewer",
]

let IS_THIS_INIT_FLAG = true


// MAIN ASYNC STORE ACTIONS DISPATCHER
// ALL ASYNC LOADERS CAN BE CENTALIZED HERE IF NEEDED
const asyncStoreDispatcher = function (to, from, next) {



    // Get stores
    const selected_store = useSelectedStore()
    const session_store = useSessionStore()
    const app_store = useAppStore()
    const constructibles_store = useConstructiblesStore()
    const viewer_store = useViewerStore()

    // Loading state (poped on afterEach)
    app_store.pushLoading("route")

    // Initial state only on first load
    if (IS_THIS_INIT_FLAG) {
        state_init(to)
        IS_THIS_INIT_FLAG = false
    }


    // Promise list for route
    let promises = []

    // Flags
    let go_to_first = false

    // Determine UUIDs & loading flags
    const current_pr = selected_store.selected_project?.uuid
    const following_pr = to.params?.project_uuid
    const load_pr = following_pr && current_pr != following_pr

    const current_mod = selected_store.selected_mod?.uuid
    const following_mod = to.params?.mod_uuid
    const load_mod = following_mod && current_mod != following_mod
    const clean_mod = !following_mod

    const current_ass = selected_store.selected_ass?.uuid
    const following_ass = to.params?.ass_uuid
    const load_ass = following_ass && current_ass != following_ass
    const clean_ass = !following_ass

    const current_modd = selected_store.selected_modd?.uuid
    const following_modd = to.params?.modd_uuid
    const load_modd = following_modd && current_modd != following_modd

    const current_assd = selected_store.selected_assd?.uuid
    const following_assd = to.params?.assd_uuid
    const load_assd = following_assd && current_assd != following_assd



    // Project loader
    // This only works if the project changes actually in route
    if (load_pr) {
        app_store.pushLoading("project")

        console.log("[v] Selecting project in route watcher. Will launch store selector")


        promises.push(selected_store.loadProject(following_pr))
        promises.push(constructibles_store.loadMyFirstConstructible(following_pr))
        go_to_first = session_store.iam_prod_worker && !PROD_USER_VIEWS.includes(from.name)

    }

    // Set viewer mode
    if (following_mod || following_modd) viewer_store.setLevel("module")
    if (following_ass || following_assd) viewer_store.setLevel("assembly")

    // ALWAYS remove designs mode for prod workers
    if (session_store.iam_prod_worker) viewer_store.setDesignsMode(false)

    // Load selections for viewer
    if (load_mod) {
        app_store.pushLoading("constructible_" + following_mod)
        console.log("[v] Loading module through route watcher.")
        promises.push(selected_store.loadModule(following_mod))
    }
    if (clean_mod) {
        selected_store.setModule(null)
    }
    if (load_ass) {
        app_store.pushLoading("constructible_" + following_ass)
        console.log("[v] Loading assembly through route watcher.")
        promises.push(selected_store.loadAssembly(following_ass))
    }
    if (clean_ass) {
        selected_store.setAssembly(null)
    }
    if (load_modd) {
        app_store.pushLoading("constructible_" + following_modd)
        console.log("[v] Loading module design through route watcher.")
        promises.push(selected_store.loadModuleDesign(following_modd))
    }
    if (load_assd) {
        app_store.pushLoading("constructible_" + following_assd)
        console.log("[v] Loading assembly design through route watcher.")
        promises.push(selected_store.loadAssemblyDesign(following_assd))
    }


    // IMPORTANTE. SI ESTA EN EL VIEWER Y ES PROD USER, AL CARGAR PRIMER PANEL
    // SE TIENE QUE REDIRIGIR AL USER AL PRIMER PANEL (O MODULO)

    Promise.all(promises)
        // redirect to viewer if prod user
        .then(() => {
            if (go_to_first) next({ name: "viewer" })
        })
        // Finally call next() when all promises ran
        .finally(() => {
            app_store.popLoading("project")
            app_store.popLoadingIncludes("constructible")
            next()
        })
}


const afterEachGuard = function (to) {
    const app_store = useAppStore()
    app_store.setRouteName(to.name)
    document.title = `produuz.it · ${to.name}`

    // Loading state (pushed on afterEach)
    app_store.popLoading("route")
}

export {
    viewerMode,
    managerMode,
    logisticsMode,
    onlyManagersGuard,
    onlyAdminsGuard,
    hasSessionObjectGuard,
    isAuthenticated,
    hasModuleForecast,
    hasModuleProduction,
    prevConstHasRCPRunning,
    asyncStoreDispatcher,
    afterEachGuard,
}